<template>
  <div id="four-o-four-view" class="tw-min-h-screen">
    <div class="container tw-px-4 tw-py-12 md:tw-px-8 lg:tw-px-12">
      <div class="sm:tw-grid sm:tw-grid-cols-2 tw-items-center">
        <div>
          <h3 class="tw-text-5xl tw-font-bold tw-mb-12">Oops!</h3>
          <p
            class="sub-header sm:tw-w-full tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-mb-6"
          >
            We can’t seem to find the page you are looking for.
          </p>
          <p class="text sm:tw-w-full md:tw-text-xl">
            It may be expired or there could be a typo. Maybe you can find what
            you need on one of the pages below.
          </p>
          <ul
            v-if="details"
            class="tw-w-96 tw-grid tw-grid-cols-3 tw-gap-x-2 tw-gap-y-4 tw-mt-8"
          >
            <li v-for="(link, index) in loggedInLink" :key="index">
              <router-link
                :to="{ name: link.name }"
                class="tw-flex tw-cursor-pointer tw-text-primary tw-font-bold hover:tw-underline hover:tw-underline-offset-8"
              >
                {{ link.title }}
              </router-link>
            </li>
          </ul>
          <ul v-if="!details" class="tw-grid tw-grid-cols-3 tw-mt-8">
            <li v-for="(link, index) in notLoggedInLink" :key="index">
              <a
                class="tw-inline-block tw-text-primary tw-font-bold tw-mt-2 hover:tw-underline tw-underline-offset-8"
                :href="link.name"
                rel="noopener noreferrer"
              >
                {{ link.title }}
              </a>
            </li>
          </ul>
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1649409363/getequityV2/crowdraise/404_it7jbn.webp"
            alt="404"
          />
          <p class="tw-text-center">Illustration from error404.fun</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getItem } from "@/utils/storage.js";

  export default {
    name: "404",

    data() {
      return {
        details: "",
        loggedInLink: [
          { title: "Home", name: "OverviewDetails" },
          { title: "Invest", name: "Invest" },
          { title: "Deals", name: "Deals" },
          { title: "Transactions", name: "Transactions" },
          { title: "Wallet", name: "Wallet" },
          { title: "Members", name: "Members" },
          { title: "Account", name: "Account" },
        ],
        notLoggedInLink: [
          { title: "Home", name: "https://www.getequity.io/" },
          { title: "Crowdraise", name: "https://www.getequity.io/crowdraise" },
          { title: "Investor App", name: "https://app.getequity.io/" },
          {
            title: "Syndicate",
            name: "https://dealroom.getequity.io/syndicate",
          },
          {
            title: "Communities",
            name: "https://dealroom.getequity.io/community",
          },
        ],
      };
    },

    mounted() {
      this.details = getItem("org_token");
    },
  };
</script>

<style lang="scss" scoped>
  #four-o-four-view {
    @include fadeIn;
    background: linear-gradient(
      90deg,
      rgba(255, 239, 186, 0.3) 0%,
      rgba(228, 255, 250, 0.4) 56.77%,
      #fdfff5 100%
    );

    .container {
      @include fadeIn;

      @media screen and (min-width: 1024px) {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
      }

      .sub-header {
        width: 20rem;

        @media screen and (min-width: 768px) {
          width: 22rem;
        }

        @media screen and (min-width: 1024px) {
          width: 26rem;
        }
      }

      .text {
        width: 20rem;

        @media screen and (min-width: 768px) {
          width: 22rem;
        }
        @media screen and (min-width: 1024px) {
          width: 26rem;
        }
      }
    }
  }
</style>
